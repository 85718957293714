import { useIntl } from 'react-intl';

import SEO from '@components/ui/SEO';
import { tos } from '@intl/generic';

const TosPage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage(tos);

  return (
    <section className="max-w-xl w-full">
      <SEO pageTitle={title} />
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores hic nesciunt aut temporibus expedita nam
      dignissimos enim error. Expedita blanditiis, autem vero natus enim tempore molestiae. Consectetur possimus facere
      assumenda!
    </section>
  );
};

export default TosPage;
